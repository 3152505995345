<template>
  <div class="custom_form_container">
    <div class="custom_form_top">
      <div class="custom_form_title">
        {{ currentTemplateName }}
      </div>
      <el-button v-if="isUserPrefabricatedWorkOrder !== '1'" class="saveSetting" type="primary" @click="saveModelSettingHandle">保存</el-button>
    </div>
    <div class="custom_form_body">
      <div class="custom_form_order_gress">
        <timelineGress
          @active-id="activeProcessChange"
          :activities="cusBusinessProcessList"
          :activePointIndex="activePointIndex"
          :drawingList="drawingList"
        />
      </div>
      <div class="custom_form_content_box">
        <div class="left-board">
          <el-scrollbar class="left-scrollbar">
            <div class="components-list">
              <div v-for="(item, listIndex) in leftComponents" :key="listIndex">
                <div class="components-title">
                  {{ item.title }}
                </div>
                <draggable
                  class="components-draggable"
                  :list="item.list"
                  :group="{ name: 'componentsGroup', pull: 'clone', put: false }"
                  :clone="cloneComponent"
                  draggable=".components-item.enbale-draggable"
                  :sort="false"
                  :move="handleMove"
                  @end="onEnd"
                >
                  <div
                    v-for="(element, index) in filterComponent(item.list)"
                    :key="index"
                    :title="element.__config__.label"
                    :class="{ 'components-item': true, 'enbale-draggable': !tableListComponentMap[element.id] }"
                    @click="addComponent(element)"
                  >
                    <template v-if="tableListComponentMap[element.id]">
                      <el-tooltip
                        effect="dark"
                        :open-delay="200"
                        :content="getTableComponentTip(element)"
                        placement="top-start"
                      >
                        <div class="components-body">
                          <i :class="[element.__config__.tagIcon, 'iconfont']"></i>
                          {{ element.__config__.label }}
                        </div>
                      </el-tooltip>
                    </template>
                    <template v-else>
                      <div class="components-body">
                        <i :class="[element.__config__.tagIcon, 'iconfont']"></i>
                        {{ element.__config__.label }}
                      </div>
                    </template>
                  </div>
                </draggable>
              </div>
            </div>
          </el-scrollbar>
        </div>

        <div class="center-board">
          <div class="center-scrollbar">
            <el-form
              :size="formConf.size"
              :label-position="formConf.labelPosition"
              :label-width="formConf.labelWidth + 'px'"
            >
              <draggable
                class="drawing-board"
                :list="drawingList"
                group="componentsGroup"
                :fallbackOnBody="true"
                :swapThreshold="0.65"
                direction="vertical"
                @add="onAdd"
                :move="handleMove"
              >
                <draggable-item
                  v-for="(item, index) in filteredDrawlist"
                  :key="item.renderKey"
                  :drawing-list="drawingList"
                  :current-item="item"
                  :index="index"
                  :active-id="activeId"
                  :form-conf="formConf"
                  :activeProcessId="activeProcessId"
                  @activeItem="activeFormItem"
                  @deleteItem="drawingItemDelete"
                  @add="onBoardAdd"
                  :move="handleMove"
                />
              </draggable>
              <div v-show="!drawingList || !drawingList.length" class="empty-info">
                从左侧拖入或点选组件进行表单设计
              </div>
            </el-form>
          </div>
        </div>
        <right-panel
          :isAdmin="isAdmin"
          :active-data="activeData"
          :process="currentActivedProcess"
          :processIndex="activePointIndex"
          :form-conf="formConf"
          @fetch-data="fetchData"
          @toggle-item-visible="resetItemVisibleFilter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import draggable from 'vuedraggable';
import RightPanel from './RightPanel2';
import timelineGress from './timelineGress.vue';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import {
  inputComponents,
  selectComponents,
  layoutComponents,
  customComponents,
  formConf,
} from '@/components/form-generator/generator/config';
import DraggableItem from './DraggableItem';
import { getIdGlobal } from '@/components/form-generator/utils/db';
import { viewProcessApi, updateProcessApi, getComponentListWithLaoutByConfigId } from '@/api/formSetting';

let oldActiveId;
let tempActiveData;
const idGlobal = getIdGlobal();

export default {
  name: 'form_setting_index',
  components: {
    draggable,
    RightPanel,
    DraggableItem,
    timelineGress,
  },
  directives: { Clickoutside },
  props: {
    code: {
      type: String,
    },
    isUserPrefabricatedWorkOrder: {
      type: String,
    },
  },
  data () {
    return {
      /*  需要同步到后续流程的添加和删除操作，保存按钮同步数据
       *  addNeed： 需后续流程新增的数组  delNeed： 需后续流程删除的数组 ,数据扁平
       */
      addNeed: [],
      delNeed: [],
      idGlobal,
      formConf,
      labelWidth: 100,
      drawingList: [],
      activeId: null,
      formData: {},
      activeData: null,
      leftComponents: [
        {
          title: '自由控件',
          list: inputComponents.concat(selectComponents).concat(layoutComponents.filter(t=>t.__config__.layout === 'childFormItem')),
        },
        {
          title: '系统控件',
          list: customComponents,
        },
        {
          title: '业务组件',
          list: [],
        },
      ],
      activeProcessId: '',
      cusBusinessProcessList: [],
      currentTemplateName: '',
      currentTemplateId: '',
      activePointIndex: 0,
      itemListVisibleFilter: () => true,
      currentConfigId: null,
      tableListComponentMap: {},
    };
  },
  computed: {
    currentActivedProcess () {
      if (!this.cusBusinessProcessList || !this.cusBusinessProcessList?.length) {
        return null;
      } else {
        return this.cusBusinessProcessList[this.activePointIndex];
      }
    },
    filteredDrawlist () {
      if (this.activePointIndex === 0) {
        return this.drawingList;
      }
      return this.drawingList ? this.drawingList.filter(this.itemListVisibleFilter) : [];
    },
    isAdmin () {
      return process.env.VUE_APP_HOST_ENV !== 'pro' || this.userInfo.phoneNumber === '18701008816'; //eslint-disable-line
    },
  },
  provide () {
    return {
      getFieldList: () => this.drawingList,
    };
  },
  created () {
    this.queryProcessLayoutHandle();
    this.resetItemVisibleFilter();
    this.userInfo = this.$local.get('userInfo') ? JSON.parse(this.$local.get('userInfo')) : {};
    // window.addEventListener('beforeunload', this.handleTabClosed);
  },
  watch: {
    activeProcessId: {
      immediate: true,
      handler (val) {
        this.drawingList = this.cusBusinessProcessList.find(
          i => i.status === val,
        )?.customizeLayoutResDTO?.schemaDesc.fields;
      },
    },
    // eslint-disable-next-line func-names
    'activeData.__config__.label': function (val, oldVal) {
      if (
        this.activeData?.placeholder === undefined
        || !this.activeData?.__config__.tag
        || oldActiveId !== this.activeId
      ) {
        return;
      }
      this.activeData.placeholder = this.activeData.placeholder.replace(oldVal, '') + val;
    },
    activeId: {
      handler (val) {
        oldActiveId = val;
      },
      immediate: true,
    },
  },
  methods: {
    filterComponent (componentList) {
      if (this.isAdmin) {
        return componentList;
      } else {
        return componentList.filter(
          t => ['c-spare-part-list', 'c-maintenance-list', 'c-work-hour'].indexOf(t.__config__.tag) < 0,
        );
      }
    },
    updateTableComponentCache (t, action = 'ADD') {
      if (t.__config__.layout === 'groupRowFormItem') {
        t.__config__.children
          && t.__config__.children.forEach(m => {
            this.updateTableComponentCache(m, action);
          });
      } else if (t.__config__.layout === 'tableColumnFormItem') {
        if (action === 'ADD') {
          this.tableListComponentMap[t.id] = t;
        } else {
          delete this.tableListComponentMap[t.id];
        }
      }
    },
    getTableComponentTip (item) {
      let component = this.tableListComponentMap[item.id];
      let step = this.cusBusinessProcessList.find(t => t.status === component?.__config__?.stepSourceId);
      return `该组件已被${step?.nodeName}流程节点使用，无法重复添加`;
    },
    checkConfigIsEdited () {
      return JSON.stringify(this.oldConfigBackUp) !== JSON.stringify(this.cusBusinessProcessList);
    },
    resetItemVisibleFilter (flag = 0) {
      // flage =1 显示所有,flag=0 仅展示当前节点创建的控件
      if (flag === 1) {
        this.itemListVisibleFilter = () => true;
      } else {
        this.itemListVisibleFilter = t => t?.__config__.stepSourceId === this.activeProcessId;
      }
    },
    handleMove (event) {
      // ***拖动对象为分组***
      if (event.draggedContext.element.__config__.layout === 'groupRowFormItem') {
        // 禁用组嵌套
        if (event.to.className.indexOf('group-wrapper') >= 0) {
          return false;
        }
        return true;
      }
      // ***拖动对象为字段时***
      // 继承分组里不能添加组件
      if (event.relatedContext?.element && event.relatedContext.element.__config__.stepSourceId !== this.activeProcessId) {
        // 左侧新增拖入
        if (!event.draggedContext.element.__config__.renderKey) {
          return false;
        }
        // 设计器中不同分组之间拖拽
        if (
          event.draggedContext.element.__config__.stepSourceId !== event.relatedContext.element.__config__.stepSourceId
        ) {
          return false;
        }
      }

      // 拖动的为固定字段时
      if (
        event.draggedContext.element.__config__.layout === 'colFormItem'
        && event.draggedContext.element.__config__.isReservedField
      ) {
        // 不能往空白中拖入
        if (event.to.className.indexOf('drawing-board') >= 0) {
          return false;
        }
        if (event.relatedContext.element) {
          // 目标所在分组与拖动控件所在分组一致
          if (
            event.relatedContext.element.__config__.layout === 'groupRowFormItem'
            && event.draggedContext.element.__config__.parentKey === event.relatedContext.element.__config__.renderKey
          ) {
            return true;
          } else if (
            event.relatedContext.element.__config__.layout === 'colFormItem'
            && event.draggedContext.element.__config__.parentKey === event.relatedContext.element.__config__.parentKey
          ) {
            return true;
          }
        }
        return false;
      }

      // 拖动的为非当前节点创建的组件
      if (
        event.draggedContext.element.__config__.stepSourceId
        && event.draggedContext.element.__config__.stepSourceId !== this.activeProcessId
      ) {
        // 不能往空白中拖入
        if (event.to.className.indexOf('drawing-board') >= 0) {
          return false;
        }
        if (event.relatedContext.element && event.relatedContext.element.__config__) {
          let config = event.relatedContext.element.__config__;
          if (
            config.layout === 'groupRowFormItem'
            && event.draggedContext.element.__config__.parentKey === config?.renderKey
          ) {
            // 当目标节点为分组控件时，判断当前的parentKey与分组的renderKey是否一致
            return true;
          } else if (event.draggedContext.element.__config__.parentKey === config?.parentKey) {
            // 当目标组件为非分组组件时，判断关联的组件parentKey与当前的parentKey是否一致
            return true;
          }
        }
        return false;
      }
      // 拖往分组内，正常拖入即可
      if (event.to.className.indexOf('group-wrapper') >= 0) {
        let child = this.cloneComponent(event.draggedContext.element);
        if (event.relatedContext.element) {
          if (event.relatedContext.element.__config__.layout === 'groupRowFormItem') {
            child.__config__.parentKey = event.relatedContext.element.__config__?.renderKey;
          } else {
            child.__config__.parentKey = event.relatedContext.element.__config__.parentKey;
          }
        } else {
          child.__config__.parentKey = event.relatedContext.component?.$attrs?.groupKey;
        }
        // renderKey不更新
        child.__config__.renderKey = event.draggedContext.element.__config__.renderKey || child.__config__.renderKey;
        event.dragged['_underlying_vm_'] = child;
        return true;
      } else {
        let groupContainer = layoutComponents.find(t => t.__config__.layout === 'groupRowFormItem');
        groupContainer = this.cloneComponent(groupContainer);
        let child = this.cloneComponent(event.draggedContext.element, groupContainer);
        child.__config__.renderKey = event.draggedContext.element.__config__.renderKey || child.__config__.renderKey;
        groupContainer['__config__'].children = [child];
        event.dragged['_underlying_vm_'] = groupContainer;
        return true;
      }
    },
    activeProcessChange (status, index) {
      this.inheritHandle();
      this.activeProcessId = status;
      this.activePointIndex = index;
      this.activeFormItem();
      this.resetItemVisibleFilter();
    },
    // 保存和切换节点继承该节点操作数据
    inheritHandle () {
      const curIndex = this.cusBusinessProcessList.findIndex(i => i.status === this.activeProcessId);

      this.cusBusinessProcessList[curIndex].customizeLayoutResDTO.schemaDesc.fields = this.drawingList;

      this.cusBusinessProcessList.forEach(item => {
        item.customizeLayoutResDTO.schemaDesc = { ...item.customizeLayoutResDTO.schemaDesc, ...this.formConf };
      });
      if (this.addNeed.length) {
        this.cusBusinessProcessList.forEach((item, ind) => {
          if (ind > curIndex) {
            const fields = item.customizeLayoutResDTO.schemaDesc.fields;
            this.addNeed.forEach(addItem => {
              if (addItem.layout === 'groupRowFormItem') {
                if (!fields.find(i => i.__config__.renderKey === addItem.renderKey)) {
                  fields.push(addItem.filConfig);
                }
              } else {
                fields.forEach(field => {
                  if (!field.__config__.children) field.__config__.children = [];
                  if (field.__config__.renderKey === addItem.parentKey) {
                    if (!field.__config__.children.find(i => i.__config__.renderKey === addItem.renderKey)) {
                      field.__config__.children.push(addItem.filConfig);
                    }
                  }
                });
              }
            });
          }
        });
      }
      if (this.delNeed.length) {
        this.cusBusinessProcessList.forEach((item, ind) => {
          if (ind > curIndex) {
            const fields = item.customizeLayoutResDTO.schemaDesc.fields;
            this.delNeed.forEach(delItem => {
              if (delItem.delType === 'groupRowFormItem') {
                const delIndex = fields.findIndex(field => field.__config__.renderKey === delItem.delRenderKey);
                fields.splice(delIndex, 1);
              } else {
                const delIndex = fields.findIndex(field => field.__config__.renderKey === delItem.delParentKey);
                if (delIndex > -1) {
                  const delChilds = fields[delIndex].__config__.children;
                  const delChildIndex = delChilds.findIndex(i => i.__config__.renderKey === delItem.delRenderKey);
                  delChilds.splice(delChildIndex, 1);
                }
              }
            });
          }
        });
      }
      this.drawingList.forEach(draw => {
        const config = draw.__config__;
        this.cusBusinessProcessList.forEach((item, ind) => {
          if (ind > curIndex) {
            const fields = item.customizeLayoutResDTO.schemaDesc.fields;
            fields.forEach(field => {
              if (field.__config__.renderKey === config.renderKey) {
                const snapConfig = _.cloneDeep(config);
                snapConfig.children.forEach(snap => {
                  field.__config__.children.forEach(fieldChild => {
                    if (snap.__config__.renderKey === fieldChild.__config__.renderKey) {
                      snap.disabled = fieldChild.disabled;
                      snap.readonly = fieldChild.readonly;
                      snap.__config__.visible = fieldChild.__config__.visible;
                    }
                  });
                });
                field.__config__ = snapConfig;
              }
            });
          }
        });
      });
      this.initSnapData();
    },
    initSnapData () {
      // 清空节点临时操作数据
      this.addNeed = [];
      this.delNeed = [];
    },
    queryProcessLayoutHandle () {
      viewProcessApi({ code: this.code }).then(rs => {
        const { customizeBusinessProcessResDTOList, customizeBusinessTypeResDTO } = rs.body;
        this.oldConfigBackUp = _.cloneDeep(customizeBusinessProcessResDTOList);
        this.cusBusinessProcessList = _.cloneDeep(customizeBusinessProcessResDTOList);
        this.currentTemplateName = customizeBusinessTypeResDTO.name;
        this.currentTemplateId = customizeBusinessTypeResDTO.id;
        this.activeProcessId = this.cusBusinessProcessList[0]?.status;

        if (this.cusBusinessProcessList[0]?.customizeLayoutResDTO?.schemaDesc) {
          this.drawingList = this.cusBusinessProcessList[0].customizeLayoutResDTO.schemaDesc?.fields;
          let cloneSchemaDesc = _.cloneDeep(this.cusBusinessProcessList[0]?.customizeLayoutResDTO?.schemaDesc);
          delete cloneSchemaDesc.fields;
          this.formConf = { ...this.formConf, ...cloneSchemaDesc };
        }

        getComponentListWithLaoutByConfigId({
          status: true,
          customizeSystemConfigId: customizeBusinessTypeResDTO.customizeSystemConfigId,
          customizeSystemConfigCode: customizeBusinessTypeResDTO.customizeSystemConfigCode,
        }).then(rsp => {
          this.leftComponents[2].list.splice(0, this.leftComponents[2].list.length);
          rsp.body.forEach(t => {
            if (t.customizeLayoutResDTO?.schemaDesc) {
              let config = t.customizeLayoutResDTO?.schemaDesc?.__config__;
              if (config) {
                config.componentName = t.name;
                config.label = t.name;
              }
              this.leftComponents[2].list.push(t.customizeLayoutResDTO?.schemaDesc);
            }
          });
        });
        // 统计已使用业务组件
        this.tableListComponentMap = {};
        this.cusBusinessProcessList.forEach(t => {
          t?.customizeLayoutResDTO?.schemaDesc?.fields
            && t?.customizeLayoutResDTO?.schemaDesc?.fields.forEach(m => {
              this.updateTableComponentCache(m);
            });
        });
      });
    },
    saveModelSettingHandle () {
      // 校验分组名称合法(不能重复)
      if (this.drawingList?.length) {
        let curRowLabels = this.drawingList.map(i => i.__config__.label);
        if (curRowLabels.length !== [...new Set(curRowLabels)].length) {
          return this.$message.error('分组名称不能重复');
        }
        for (let i = 0; i < this.drawingList.length; i++) {
          let curColLabels = this.drawingList[i].__config__.children
            .filter(t => t.__config__.tag !== 'span')
            .map(i => i.__config__.label);
          if (curColLabels.length !== [...new Set(curColLabels)].length) {
            return this.$message.error('同一分组内控件名称不能重复');
          }
        }
      }

      this.inheritHandle(); // 先完成目标同步新增删除，然后保存
      const dataSave = _.cloneDeep(this.cusBusinessProcessList);
      const customizeBusinessProcessReqDTOList = dataSave.map(point => {
        const curData = {
          ...point,
          customizeLayoutReqDTO: point.customizeLayoutResDTO,
          customizeButtonReqDTOList: point.customizeButtonResDTOList,
        };
        delete curData.customizeLayoutResDTO;
        delete curData.customizeButtonResDTOList;
        return curData;
      });
      const params = {
        id: this.currentTemplateId,
        code: this.code,
        name: this.currentTemplateName,
        customizeBusinessProcessReqDTOList,
      };
      updateProcessApi(params).then(() => {
        this.$message.success('保存成功');
        this.queryProcessLayoutHandle();
        this.activePointIndex = 0;
      });
    },
    setObjectValueReduce (obj, strKeys, data) {
      const arr = strKeys.split('.');
      arr.reduce((pre, item, i) => {
        if (arr.length === i + 1) {
          pre[item] = data;
        } else if (!_.isObject(pre[item])) {
          pre[item] = {};
        }
        return pre[item];
      }, obj);
    },
    setRespData (component, resp) {
      const { dataPath, renderKey, dataConsumer } = component.__config__;
      if (!dataPath || !dataConsumer) return;
      const respData = dataPath.split('.').reduce((pre, item) => pre[item], resp);

      // 将请求回来的数据，赋值到指定属性。
      // 以el-tabel为例，根据Element文档，应该将数据赋值给el-tabel的data属性，所以dataConsumer的值应为'data';
      // 此时赋值代码可写成 component[dataConsumer] = respData；
      // 但为支持更深层级的赋值（如：dataConsumer的值为'options.data'）,使用setObjectValueReduce
      this.setObjectValueReduce(component, dataConsumer, respData);
      const i = this.drawingList.findIndex(item => item.__config__.renderKey === renderKey);
      if (i > -1) this.$set(this.drawingList, i, component);
    },
    fetchData (component) {
      const { dataType, method, url } = component.__config__;
      if (dataType === 'dynamic' && method && url) {
        this.setLoading(component, true);
        this.$axios({
          method,
          url,
        }).then(resp => {
          this.setLoading(component, false);
          this.setRespData(component, resp.data);
        });
      }
    },
    setLoading (component, val) {
      const { directives } = component;
      if (Array.isArray(directives)) {
        const t = directives.find(d => d.name === 'loading');
        if (t) t.value = val;
      }
    },
    activeFormItem (currentItem) {
      this.activeData = currentItem;
      this.activeId = currentItem?.__config__?.renderKey;
    },
    onEnd (obj) {
      if (obj.from !== obj.to) {
        this.fetchData(tempActiveData);
        this.activeData = tempActiveData;
        this.activeId = this.idGlobal;
      }
    },
    addComponent (item) {
      if (this.tableListComponentMap[item.id]) {
        return;
      }
      let groupContainer = layoutComponents.find(t => t.__config__.layout === 'groupRowFormItem');
      const clone = this.cloneComponent(groupContainer);
      clone['__config__'].children = [this.cloneComponent(item, clone)];
      this.fetchData(clone);
      this.drawingList.push(clone);
      this.boardAddHandle(clone);
      this.activeFormItem(clone);
    },
    onAdd (origin) {
      const clone = origin.item._underlying_vm_;
      this.boardAddHandle(clone);
    },
    onBoardAdd (origin) {
      const clone = origin.item._underlying_vm_;
      this.boardAddHandle(clone);
    },
    boardAddHandle (origin) {
      const clone = _.cloneDeep(origin);
      const config = clone.__config__;
      // 扁平数据
      if (!this.addNeed.find(i => i.renderKey === config.renderKey)) {
        if (config.layout === 'groupRowFormItem') {
          this.addNeed.push({
            renderKey: config.renderKey,
            filConfig: clone,
            layout: 'groupRowFormItem',
          });
          if (config?.children?.length) {
            config.children.forEach(child => {
              this.addNeed.push({
                parentKey: child.__config__.parentKey,
                filConfig: child,
                renderKey: child.__config__.renderKey,
              });
            });
          }
        } else {
          this.addNeed.push({
            parentKey: config.parentKey,
            filConfig: clone,
            renderKey: config.renderKey,
          });
        }
        this.updateTableComponentCache(origin);
      }
      this.addNeed.forEach(i => {
        if (i.layout === 'groupRowFormItem') {
          delete i.filConfig.__config__.children;
        }
      });
    },
    cloneComponent (origin, parent) {
      const clone = _.cloneDeep(origin);
      const config = clone.__config__;
      config.span = config.span || this.formConf.span; // 生成代码时，会根据span做精简判断
      this.createIdAndKey(clone);
      clone.placeholder !== undefined && (clone.placeholder += config.label);
      config.parentKey = parent?.__config__?.renderKey;
      config.stepSourceId = this.activeProcessId;
      tempActiveData = clone;
      return tempActiveData;
    },
    createIdAndKey (item) {
      const config = item.__config__;
      config.formId = ++this.idGlobal;
      config.renderKey = `${config.formId}${+new Date()}`; // 改变renderKey后可以实现强制更新组件
      if (config.layout === 'colFormItem') {
        item.__vModel__ = `field${this.idGlobal}`;
      } else if (config.layout === 'rowFormItem') {
        config.componentName = `row${this.idGlobal}`;
        !Array.isArray(config.children) && (config.children = []);
        // delete config.label; // rowFormItem无需配置label属性
      }
      return item;
    },
    AssembleFormData () {
      this.formData = {
        fields: _.cloneDeep(this.drawingList),
        ...this.formConf,
      };
    },
    drawingItemDelete (currentItem) {
      // 删除已使用业务组件缓存
      this.updateTableComponentCache(currentItem, 'DELETE');
      // 删除继承
      // 如果是新增的，剔除 addNeed 继承数据，如果删除的是组，剔除该组下的所有 col
      const curItemConfig = currentItem.__config__;
      const delIndex = this.addNeed.findIndex(i => i.renderKey === curItemConfig.renderKey);
      if (delIndex > -1) {
        this.addNeed.splice(delIndex, 1);
        if (curItemConfig.layout === 'groupRowFormItem') {
          this.addNeed = this.addNeed.filter(i => i.parentKey !== curItemConfig.renderKey);
        }
      } else {
        this.delNeed.push({
          delRenderKey: curItemConfig.renderKey,
          delType: curItemConfig.layout,
          delParentKey: curItemConfig.parentKey,
        });
      }
      if (curItemConfig.layout === 'groupRowFormItem') {
        const index = this.drawingList.findIndex(t => t.__config__.renderKey === curItemConfig.renderKey);
        index >= 0 && this.drawingList.splice(index, 1);
      } else {
        let parent = this.drawingList.find(t => t.__config__.renderKey === curItemConfig.parentKey);
        if (parent && parent.__config__.children) {
          let index = parent.__config__.children.findIndex(t => t.__config__.renderKey === curItemConfig.renderKey);
          index >= 0 && parent.__config__.children.splice(index, 1);
        }
      }

      this.$nextTick(() => {
        this.activeFormItem();
      });
    },
  },
  beforePageLeave (tab, type) {
    if (type === 'unload' && this.checkConfigIsEdited()) {
      return 'unload';
    }
    if (type === 'replace') {
      if (this.checkConfigIsEdited()) {
        return this.$confirm('当前页面尚未保存，确定要关闭吗？', '提示', { closeOnHashChange: false })
          .then(() => {
            return Promise.resolve(() => this.$router.push(tab.to));
          })
          .catch(() => Promise.reject());
      } else {
        return Promise.resolve();
      }
    } else {
      if (this.checkConfigIsEdited()) {
        return this.$confirm('当前页面尚未保存，确定要关闭吗？', '提示', { closeOnHashChange: false });
      } else {
        return Promise.resolve();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$selectedColor: #f6f7ff;
$lighterBlue: #409eff;
$handleBtn: #0f89ff;

.custom_form_container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
  .custom_form_top {
    position: relative;
    height: 48px;
    background: #fff;
    z-index: 1;
    border-bottom: 1px solid #dcdfe6;
    .custom_form_title {
      height: 100%;
      line-height: 48px;
      text-align: center;
      color: #242832;
      font-size: 14px;
      .editBtn {
        font-size: 18px;
        margin-left: 18px;
        color: #aaa;
        cursor: pointer;
        vertical-align: middle;
      }
    }
    .saveSetting {
      position: absolute;
      right: 20px;
      top: 11px;
      padding: 6px 24px;
    }
  }
  .custom_form_body {
    display: flex;
    height: calc(100% - 48px);
  }
  .custom_form_order_gress {
    background: #eff1f6;
    overflow: auto;
    padding: 10px 0;
    box-sizing: border-box;
    .el-timeline {
      padding: 10px 6px;
    }
  }
  .custom_form_content_box {
    justify-content: space-between;
    background: #eff1f6;
    overflow: hidden;
    display: flex;
    flex: 1;
    .left-board {
      width: 260px;
      margin-right: 10px;
      background: #fff;
      flex-shrink: 0;
      .left-scrollbar {
        height: 100%;
        overflow: hidden;
      }

      .components-list {
        padding: 8px;
        box-sizing: border-box;
        height: 100%;

        .components-draggable {
          padding-bottom: 20px;
        }
        .components-title {
          font-size: 14px;
          color: #222;
          margin: 6px 2px;
        }

        .components-item {
          display: inline-block;
          width: 48%;
          margin: 1%;
          transition: transform 0ms !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          .components-body {
            padding: 8px 10px;
            background: #eff1f6;
            font-size: 12px;
            cursor: not-allowed;
            border: 1px dashed $selectedColor;
            border-radius: 3px;
            .iconfont {
              color: #777;
              font-size: 12px;
              width: 12px;
              height: 12px;
              margin-right: 4px;
            }
          }

          &.enbale-draggable {
            .components-body {
              background: $selectedColor;
              cursor: move;
              border: 1px dashed $selectedColor;
              &:hover {
                border: 1px dashed #787be8;
                color: #787be8;
              }
            }
          }
        }
      }
    }

    .center-board {
      height: 100%;
      overflow-y: auto;
      background: #fff;
      flex: 1;

      & > .center-scrollbar {
        flex: 1;
        overflow: hidden;
        background: url('../../../../assets/imgs/iphone.png');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 375px;
        min-height: 750px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 100%;
        & > .el-form {
          width: 100%;
          height: 750px;
          overflow: hidden;
          box-sizing: border-box;
          padding: 48px 25px 48px;
          position: relative;
          /*定义滚动条轨道 内阴影+圆角*/
          ::-webkit-scrollbar-track,
          ::-webkit-scrollbar-thumb,
          ::-webkit-scrollbar-thumb:hover {
            background: transparent;
          }

          /deep/ .drawing-board {
            box-sizing: border-box;
            height: 100%;
            overflow: auto;
            overflow-x: hidden;
            position: relative;
            padding-top: 32px;
            & > .el-col {
              margin-bottom: 15px;
            }
            .components-body {
              padding: 0;
              margin: 0;
              font-size: 0;
            }
            .sortable-ghost {
              position: relative;
              display: block;
              overflow: hidden;
              &::before {
                content: ' ';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 3px;
                background: rgb(89, 89, 223);
                z-index: 2;
              }
            }
            .components-item.sortable-ghost {
              width: 100%;
              height: 60px;
              background-color: $selectedColor;
            }
            .el-form-item {
              margin-bottom: 0;
              margin-top: 0;
            }
            .drawing-item {
              position: relative;
              cursor: move;
              &.unfocus-bordered:not(.active-from-item) > div:first-child {
                border: 1px dashed #ccc;
              }
            }
            .drawing-row-item {
              position: relative;
              cursor: move;
              box-sizing: border-box;
              // border: 1px dashed #ccc;
              // border-radius: 3px;
              border-radius: 4px;
              border: 1px solid rgba(113, 121, 141, 0.16);

              padding: 0 2px;
              padding-bottom: 25px;
              .drawing-row-item {
                margin-bottom: 2px;
              }
              .el-col {
                margin-top: 15px;
              }
              & > .el-col {
                margin-top: 0;
              }
              .el-form-item {
                margin-bottom: 0;
              }
              .group-title {
                font-size: 14px;
                line-height: 26px;
                height: 26px;
                vertical-align: middle;
                color: rgba(36, 40, 50, 0.7);
                background: #ededed;
                padding-left: 24px;
                box-sizing: border-box;
                margin: 0 -2px;
              }
              .group-wrapper {
                border-top: 1px solid #ccc;
                margin: 0 -2px;
                min-height: 40px;
              }
            }
            .drawing-item,
            .drawing-row-item {
              box-sizing: border-box;
              &.active-from-item {
                background: $selectedColor;
                border: 2px solid $lighterBlue !important;
                border-radius: 4px;

                & > .el-form-item {
                  background: $selectedColor;
                  border-radius: 6px;
                }
                & > .drawing-item-copy,
                & > .drawing-item-delete,
                & > .drawing-item-left-btn-list {
                  // display: initial;
                  display: inline-block;
                  vertical-align: middle;
                }
                & > .component-name {
                  color: $lighterBlue;
                }
              }
              &:hover {
                & > .el-form-item {
                  background: $selectedColor;
                  border-radius: 6px;
                }
                // & > .drawing-item-copy, & > .drawing-item-delete{
                //   display: initial;
                // }
              }
              & > .drawing-item-delete,
              & > .drawing-item-left-btn-list {
                display: none;
                position: absolute;
                top: -30px;
                // width: 22px;
                height: 22px;
                line-height: 22px;
                text-align: center;
                border-radius: 4px;
                font-size: 12px;
                background: #0f89ff;
                cursor: pointer;
                z-index: 100;
                padding: 3px 5px;
                .el-icon-delete {
                  margin-right: 6px;
                }
              }
              & > .drawing-item-delete {
                right: 0px;
                color: #ffffff;
                background: $handleBtn;
                &:hover {
                  background: $handleBtn;
                  color: #fff;
                }

                .icon {
                  font-size: 12px;
                  margin-right: 6px;
                }
              }
              & > .drawing-item-left-btn-list {
                .icon {
                  font-size: 12px;
                  margin-right: 6px;
                }
                color: #ffffff;
                background: $handleBtn;
                &:hover {
                  background: $handleBtn;
                  color: #fff;
                }
                .left_btn_item {
                  margin: 0 10px;
                  display: inline-block;
                  vertical-align: middle;
                  color: rgba(255, 255, 255, 0.6);
                  &:hover {
                    color: rgba(255, 255, 255, 1);
                  }
                  &.active {
                    color: rgba(255, 255, 255, 1);
                  }
                }
                .left_btn_item::after {
                  color: rgba(255, 255, 255, 0.6);
                  position: relative;
                  left: 10px;
                  display: inline-block;
                  vertical-align: middle;
                  content: ' ';
                  width: 1px;
                  height: 8px;
                  background: #ccc;
                }
                .left_btn_item:last-child::after {
                  display: none;
                }
              }
            }
          }

          .empty-info {
            font-size: 14px;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
